
import { Component, Vue } from "vue-property-decorator";
import {
  apiPictureLists,
  apiPictureDel,
  apiIpShow,
  apiIpLists,
} from "@/api/shop";
import { RequestPaging } from "@/utils/util";
import LsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { PageMode } from "@/utils/type";
@Component({
  components: {
    LsPagination,
    ExportData,
    LsDialog,
  },
})
export default class BrandImage extends Vue {
  /** S Data **/
  form = {
    brand_id: 0,
  };

  // 日期选择器数据
  timeForm = [];

  // 设置用户标签弹出框
  dialogVisible = false;
  // 选中用户标签
  labelValue = [];
  // 用户选择框数据
  userLevelList = {};
  userLabelList = {};
  sourceList = {};
  // 分页查询
  pager: RequestPaging = new RequestPaging();
  // 被选中的用户id
  multipleSelection = [];
  brandList: any = [];
  /** E Data **/

  /** S Methods **/
  apiPictureLists = apiPictureLists; // 传递给导出组件的api

  // 选中的用户触发事件
  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  // 查询按钮
  query() {
    this.pager.page = 1;
    this.getList();
  }

  // 获取用户列表数据
  getList() {
    this.pager.request({
      callback: apiPictureLists,
      params: {
        ...this.form,
      },
    });
  }

  // 获取品牌
  getBrandList() {
    apiIpLists({}).then((res) => {
      this.brandList = res.lists;
      this.brandList.unshift({ id: 0, brand_name: "全部" });
    });
  }

  // 重置按钮
  onReset() {
    this.form = {
      brand_id: 0,
    };
    this.timeForm = [];
    this.getList();
  }

  // 打开设置用户标签弹窗
  openDialogVisible() {
    if (!this.multipleSelection) {
      this.$message.error("请选择用户!");
      return;
    }
    if (this.multipleSelection.length <= 0) {
      this.$message.error("请选择用户!");
      return;
    }
    this.dialogVisible = true;
  }

  // 新增图片
  onPicAdd() {
    this.$router.push({
      path: "/designer/brand_image_edit",
      query: {
        mode: PageMode.ADD,
      },
    });
  }

  // 编辑图片
  onPicEdit(item: any) {
    this.$router.push({
      path: "/designer/brand_image_edit",
      query: {
        mode: PageMode.EDIT,
        info: item,
      },
    });
  }

  // 批量删除商品
  handleBatchDelete(ids: any) {
    apiPictureDel({
      id: ids,
    }).then(() => {
      this.getList();
    });
  }

  handleStatus(value: number, id: number) {
    apiIpShow({
      id,
      is_show: value,
    }).then(() => {
      this.getList();
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    this.getList();
    this.getBrandList();
  }
  /** E Life Cycle **/
}
